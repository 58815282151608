.vis-timeline {
  overflow-y: hidden;
}
.vis-time-axis .vis-grid.vis-odd {
  background: #ffffff;
}

.vis-group,
.vis-content {
  height: 48px;
}

.vis-range .proibido {
  top: 0px;
  transform: translateY(-4px);
}

.vis-background {
  position: initial;
}

.vis-text {
  font-family: "monospace";
}

.vis-text > div {
  font-family: "monospace";
  font-size: 15px;
  font-style: italic;
  font-weight: lighter;
}

.vis-text.vis-minor {
  font-family: monospace;
  font-size: 17px;
}
.vis-custom-time {
  pointer-events: none;
  background-color: #df08de;
}
.vis-current-time {
  pointer-events: all;
  /* left: 50%;
      transform: translateX(0) !important; */
}

/* gray background in weekends, white text color */
.vis-time-axis .vis-grid.vis-saturday,
.vis-time-axis .vis-grid.vis-sunday {
  background: gray;
}

.vis-time-axis .vis-text.vis-saturday,
.vis-time-axis .vis-text.vis-sunday {
  color: white;
}

.proibido {
  width: 200px;
  height: 48px;
    top: 0px !important;
  background: repeating-linear-gradient(45deg,
      /* Ângulo da linha diagonal */
      red,
      /* Cor das linhas diagonais */
      red 10px,
      /* Espessura das linhas diagonais */
      transparent 10px,
      /* Espaço vazio entre as linhas */
      transparent 20px
      /* Espaço vazio entre as linhas */
    );
}

.clip-download-area {
  width: 200px;
  height: 48px;
  top: 0px !important;
  background-color: lightgreen;
}